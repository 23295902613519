.project-details {
    margin-bottom: .5px;
    padding: px2rem(124px) 0 px2rem(70px);
    //返回按钮
    .back {
        position: absolute;
        top: -8px;
        right: 0;
        transform: translateY(-100%);
        a {
            display: block;
            padding: px2rem(16px) 58px;
            font-size: 16px;
            color: #fff;
            background-color: #f09c49;
            border-radius: 4px;
            &:hover {
                background-color: $hoverColor;
            }
        }
    }
    &-box {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 45px 65px 45px 45px;
        background-color: #fff;
        .swiper-container {
            margin: 0;
            width: percent(827, 1250);
        }
        .swiper-slide {
            padding-top: percent(526, 827);
            height: 0;
            @include img();
        }
    }
    .project-info {
        width: percent(344, 1250);
    }
    .swiper-button {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            position: static;
            outline: none;
            width: 56px;
            height: 56px;
            &::after {
                content: "\e631";
                font-family: "iconfont";
                font-size: 18px;
                color: #fff;
            }
        }
        .swiper-button-prev {
            background-color: #0087da;
        }
        .swiper-button-next {
            background-color: #f5932a;
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .swiper-pagination {
        display: none;
        .swiper-pagination-bullet {
            opacity: 1;
            background-color: #fff;
        }
        .swiper-pagination-bullet-active {
            background-color: #f09c49;
        }
    }
    .article {
        @include articleReset(15px, 32px);
        p {
            line-height: 32px;
            color: #777;
        }
        .h3 {
            margin: 65px 0 20px;
            padding-bottom: px2rem(25px);
            font-size: 28px;
            font-weight: 700;
            color: #555;
            border-bottom: 1px dashed #bebebe;
        }
    }
    @media (max-width: 1365px) {
        &-box {
            padding: px2rem(40px);
        }
        .project-info {
            width: 32%;
        }
        .article {
            .h3 {
                margin: px2rem(20px) 0;
            }
        }
    }
    @media (max-width: 768px) {
        &-box {
            flex-wrap: wrap;
            .swiper-container {
                width: 100%;
            }
        }
        .project-info {
            width: 100%;
        }
        .swiper-button {
            display: none;
        }
        .swiper-pagination {
            display: block;
        }
        .article {
            .h3 {
                font-size: px2rem(40px);
            }
        }
        .back {
            position: static;
            width: 100%;
            transform: translateY(0);
            text-align: center;
        }
    }
}
.project {
    padding-bottom: px2rem(70px);
    &-menu {
        position: relative;
        margin: px2rem(40px) 0 px2rem(30px);
        .swiper-slide {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                font-size: 16px;
                background-color: #fff;
                &:hover {
                    color: #fff;
                    background-color: $hoverColor;
                }
            }
        }
        .active {
            a {
                color: #fff;
                background-color: $hoverColor;
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            outline: none;
            margin: 0;
            width: auto;
            height: auto;
            &::after {
                content: "\e631";
                font-family: "iconfont";
                font-size: 18px;
                color: #333;
            }
        }
        .swiper-button-prev {
            left: -5px;
            transform: translate(-100%, -50%);
        }
        .swiper-button-next {
            right: -5px;
            transform: translate(100%, -50%) rotate(180deg);
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        img {
            transition: all .3s ease;
        }
    }
    &-item {
        display: block;
        margin: 0 percent(10, 1360) percent(15, 1360) 0;
        width: percent(332, 1360);
        .img-box {
            padding-top: percent(212, 332);
            @include img();
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
    &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 px2rem(20px);
        height: 72px;
        background-color: #fff;
        h5 {
            font-size: 16px;
            color: #555;
            @include multiEllipsis(2);
        }
        &::after {
            content: "\e631";
            font-family: "iconfont";
            display: inline-block;
            transform: rotate(180deg);
            font-size: 12px;
            color: #555;
        }
    }
    @media (max-width: 1366px) {
        width: 1300px;
    }
    @media (max-width: 1365px) {
        width: auto;
        &-menu {
            .swiper-slide {
                a {
                    font-size: 14px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        &-menu {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
        &-item {
            width: 49%;
            margin: 0 2% 2% 0;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        &-title {
            height: 54px;
        }
    }
}
.culture-detail {
    background-color: #fff;
    margin-top: px2rem(40px);
    margin-bottom: px2rem(40px);
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(#000, .1);
    padding: px2rem(50px) px2rem(40px);
    line-height: 26px;
    @include articleReset(14px, 26px);
}
.culture {
    padding: px2rem(10px) 0 0;
    background-color: #fff;
    background-image: url(/public/assets/static/style/images/rcpy-yy_03.png);
    background-position: top center;
    background-repeat: no-repeat;
    .article {
        font-size: 14px;
        p {
            line-height: 26px;
            margin-bottom: px2rem(20px);
        }
    }
    &-title {
        margin: px2rem(80px) 0 px2rem(24px);
        font-size: 24px;
        font-weight: 700;
        color: #f0ad18;
    }
    .flex-box {
        display: flex;
        justify-content: space-between;
    }
    .i {
        padding-bottom: px2rem(80px);
        p {
            color: #777;
        }
        &-left {
            width: percent(650, 1360);
        }
        &-right {
            width: percent(665, 1360);
        }
    }
    .ii {
        position: relative;
        height: px2rem(556px);
        .bg {
            height: 100%;
            background-position: bottom center;
            background-size: auto 100%;
        }
        > .w1360 {
            position: absolute;
            width: 1360px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        &-box {
            position: absolute;
            right: 0;
            bottom: 0;
            box-sizing: border-box;
            padding: 60px 80px 70px 70px;
            width: percent(720, 1360);
            background-color: #4ba0be;
            color: #fff;
            h4 {
                margin-bottom: 20px;
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
    .iii {
        padding-top: px2rem(20px);
        .iii-left {
            width: percent(560, 1360);
        }
        .iii-right {
            img {
                max-width: 100%;
            }
        }
    }
    @media (max-width: 1400px) {
        .ii {
            > .w1360 {
                width: 1300px;
            }
        }
    }
    @media (max-width: 1365px) {
        &-title {
            margin-top: px2rem(20px);
        }
        .ii {
            > .w1360 {
                margin: 0;
                width: 100%;
            }
            &-box {
                padding: px2rem(60px);
                width: 60%;
            }
        }
    }
    @media (max-width: 768px) {
        .flex-box {
            flex-wrap: wrap;
        }
        &-title {
            font-size: 20px;
        }
        .i {
            &-left,
            &-right {
                width: 100%;
            }
        }
        .ii {
            height: auto;
            .w1360 {
                position: static;
                transform: translateX(0);
            }
            .bg {
                height: px2rem(500px);
                background-size: auto 115%;
                background-position: 16% 100%;
            }
            &-box {
                position: static;
                padding: px2rem(60px) .15rem;
                width: 100%;
                h4 {
                    font-size: 20px;
                }
            }
        }
        .iii {
            .iii-left,
            .iii-right {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
.new {
    padding: px2rem(50px) 0 px2rem(70px);
    .paged {
        padding-top: px2rem(50px);
    }
    &-time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        color: #fff;
        background-color: #848484;
        h3 {
            font-size: 36px;
        }
        h4 {
            font-size: 24px;
        }
        h6 {
            width: 100%;
            font-size: 14px;
        }
    }
    &-main {
        margin-bottom: px2rem(25px);
        padding: 30px 60px 40px 40px;
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0 20px 20px -15px rgba($color: #000, $alpha: .1);
        .img-box {
            margin-left: 1px;
            width: percent(255, 1260);
            div {
                width: 100%;
                padding-top: (225 / 255) * 100%;
                @include img();
            }
        }
        .info-box {
            padding-right: percent(170, 1260);
            width: percent(960, 1260);
            box-sizing: border-box;
            h4 {
                margin-bottom: px2rem(20px);
                font-size: 26px;
                font-weight: 700;
                line-height: 30px;
                color: #555;
                @include multiEllipsis(2);
            }
            p {
                font-size: 16px;
                line-height: 28px;
                color: #777;
                @include multiEllipsis(3);
            }
        }
        .new-time {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            text-align: center;
            background-color: $hoverColor;
        }
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
                .new-time {
                    background-color: #f5932a;
                }
            }
        }
        .swiper-pagination-bullet {
            outline: none;
            margin: 0 4px 0 0 !important;
            width: 8px;
            height: 8px;
            border-radius: 1px;
            background-color: #535353;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background-color: #0072b8;
        }
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &-item {
        padding: px2rem(30px) 0 px2rem(35px);
        width: percent(655, 1360);
        border-bottom: 1px dashed #909090;
        a {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            &:hover {
                .new-time {
                    background-color: #f5932a;
                }
            }
        }
        .new-time {
            text-align: right;
        }
        .info-box {
            width: percent(532, 655);
            h4 {
                margin-bottom: px2rem(18px);
                font-size: 18px;
                color: #000;
            }
            p {
                font-size: 14px;
                line-height: 24px;
                color: #666;
                min-height: 72px;
                @include multiEllipsis(3);
            }
        }
    }
    @media (max-width: 1024px) {
        &-item {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        &-main {
            padding: px2rem(30px);
            .img-box {
                width: 100%;
            }
            .info-box {
                width: 100%;
                padding-right: 0;
                h4 {
                    margin-top: px2rem(20px);
                }
            }
            .new-time {
                top: 0;
                transform: translate(0, 0);
            }
            .swiper-slide {
                flex-wrap: wrap;
            }
            .swiper-pagination-bullets {
                display: none;
            }
        }
    }
    @media (max-width: 750px) {
        &-time {
            width: 60px;
            height: 60px;
            h3 {
                font-size: 20px;
            }
            h4 {
                font-size: 16px;
            }
            h6 {
                font-size: 12px;
            }
        }
        &-main {
            .info-box {
                h4 {
                    font-size: 20px;
                }
            }
        }
        &-item {
            .info-box {
                box-sizing: border-box;
                padding-left: 5px;
                h4 {
                    font-size: 16px;
                    @include multiEllipsis(1);
                }
                p {
                    min-height: 48px;
                    @include multiEllipsis(2);
                }
            }
        }
    }
}
.new-details {
    padding: px2rem(70px) 0 px2rem(65px);
    .paging {
        display: flex;
        margin-top: px2rem(30px);
        li {
            width: percent(1, 3);
            height: 64px;
            background-color: $hoverColor;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 16px;
                color: #fff;
            }
            span {
                &::before,
                &::after {
                    display: inline-block;
                    font-family: "iconfont";
                    font-size: 20px;
                }
            }
            &:hover {
                background-color: #f5932a;
            }
            &:nth-child(1) {
                span {
                    &::before {
                        content: "\e65b";
                        transform: rotate(-90deg);
                        margin-right: px2rem(20px);
                    }
                }
            }
            &:nth-child(2) {
                box-sizing: border-box;
                border-left: 1px solid rgba($color: #fff, $alpha: .33);
                border-right: 1px solid rgba($color: #fff, $alpha: .33);
                span {
                    &::before {
                        content: "\e785";
                        margin-right: px2rem(16px);
                        vertical-align: middle;
                    }
                }
            }
            &:nth-child(3) {
                span {
                    &::after {
                        content: "\e65b";
                        transform: rotate(90deg);
                        margin-left: px2rem(20px);
                    }
                }
            }
        }
    }
    &-box {
        display: flex;
        background-color: #fff;
    }
    &-content {
        box-sizing: border-box;
        padding: 70px;
        width: percent(990, 1360);
        box-shadow: 10px 0 50px -1px rgba($color: #000, $alpha: .1);
        .new-title {
            margin-bottom: px2rem(30px);
            padding: 0 55px;
            text-align: center;
            border-bottom: 1px dashed #b2b2b2;
            h4 {
                font-size: 28px;
                font-weight: 700;
                color: #333;
            }
            h6 {
                margin: px2rem(16px) 0 px2rem(26px);
                font-size: 13px;
                color: #666;
            }
        }
        .new-article {
            font-size: 14px;
            p {
                margin-bottom: 13px;
                line-height: 26px;
                color: #666;
            }
            img {
                max-width: 100%;
            }
        }
    }
    &-recommend {
        box-sizing: border-box;
        padding: px2rem(65px) px2rem(43px);
        width: percent(370, 1360);
        h3 {
            font-size: 32px;
            font-weight: 700;
            color: #333;
            text-align: center;
        }
        .line {
            position: relative;
            margin: 15px 15px 25px;
            border-bottom: 1px solid rgba($color: #2095c8, $alpha: .2);
            h6 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 0 10px;
                font-size: 16px;
                color: #088ac2;
                background-color: #fff;
            }
        }
        .recommend-list {
            li {
                border-bottom: 1px dashed #e0e0e0;
                a {
                    display: block;
                    padding: 20px 0;
                }
                h5,
                h6 {
                    font-size: 16px;
                    line-height: 24px;
                }
                h5 {
                    color: #555;
                }
                h6 {
                    font-family: Arial;
                    font-weight: 700;
                    color: #0599d9;
                }
            }
        }
    }
    @media (max-width: 768px) {
        &-box {
            flex-wrap: wrap;
        }
        &-content {
            width: 100%;
            padding: px2rem(50px);
            .new-title {
                padding: 0;
            }
        }
        &-recommend {
            width: 100%;
        }
    }
    @media (max-width: 750px) {
        .paging {
            li {
                height: 48px;
                a {
                    font-size: 12px;
                }
                span {
                    &::before,
                    &::after {
                        font-size: 14px;
                    }
                }
            }
        }
        &-content {
            .new-title {
                h4 {
                    font-size: 18px;
                }
            }
        }
        &-recommend {
            h3 {
                font-size: 20px;
            }
        }
    }
}
.system {
    margin-bottom: .5px;
    .article {
        font-size: 16px;
        p {
            line-height: 30px;
            color: #666;
        }
        span {
            font-size: 18px;
            color: #666;
            font-weight: 400;
        }
        img {
            max-width: 100% !important;
        }
        .h4 {
            margin-bottom: px2rem(20px);
            font-size: 22px;
            font-weight: 700;
            color: #555;
            strong {
                margin-left: px2rem(20px);
                font-size: 48px;
                color: #f09c49;
            }
        }
    }
    &-introduce {
        padding-bottom: 60px;
    }
    &-list {
        display: flex;
        margin-top: px2rem(20px);
        margin-bottom: px2rem(45px);
        li {
            padding: px2rem(60px) 0;
            width: 25%;
            background-color: #fff;
            text-align: center;
            h6 {
                margin-top: px2rem(20px);
                font-size: 18px;
                color: #666;
            }
            &:nth-child(2n) {
                background-color: #fafafa;
            }
        }
    }
    &-bottom {
        padding: px2rem(125px) 0 px2rem(110px);
        background-image: url(/public/assets/static/style/images/tx-bg_02.jpg);
        background-repeat: no-repeat;
        background-position: top center;
        .swiper-box {
            position: relative;
            .swiper-button-prev,
            .swiper-button-next {
                outline: none;
                width: auto;
                &::after {
                    content: "\e631";
                    font-family: "iconfont";
                    font-size: 24px;
                    color: #fff;
                }
            }
            .swiper-button-prev {
                left: -10px;
                transform: translate(-100%, 0);
            }
            .swiper-button-next {
                right: -10px;
                transform: translate(100%, 0) rotate(180deg);
            }
        }
        .system-swiper {
            background-color: #fff;
            .swiper-slide {
                box-sizing: border-box;
                padding: px2rem(20px) 0;
                border-right: 1px solid rgba($color: #fff, $alpha: .1);
                text-align: center;
                background-color: #e6a411;
                cursor: pointer;
                h6 {
                    font-size: 16px;
                    line-height: 20px;
                    color: #fff;
                }
            }
            .on {
                background-color: #fff;
                h6 {
                    color: #000;
                }
            }
        }
        .img-info {
            padding: px2rem(65px) px2rem(20px) px2rem(105px);
            box-sizing: border-box;
            text-align: center;
            font-size: 16px;
            background-color: #fff;
            img {
                margin-top: px2rem(30px);
                max-width: 100%;
            }
            p {
                line-height: 30px;
                color: #777;
                margin: 0 auto;
                width: 970px;
                max-width: 100%;
            }
            .h4 {
                margin-bottom: px2rem(25px);
                font-size: 26px;
                font-weight: 700;
                color: #444;
            }
        }
        .box {
            margin-top: -1px;
        }
    }
    @media (max-width: 768px) {
        &-bottom {
            .swiper-box {
                .swiper-button-prev,
                .swiper-button-next {
                    &::after {
                        color: $hoverColor;
                    }
                }
                .swiper-button-prev {
                    left: px2rem(10px);
                    transform: translate(0, 0);
                }
                .swiper-button-next {
                    right: px2rem(10px);
                    transform: translate(0, 0) rotate(180deg);
                }
            }
        }
    }
    @media (max-width: 750px) {
        .article {
            font-size: 14px;
            p {
                line-height: 2;
            }
            span {
                font-size: 14px;
            }
            .h4 {
                font-size: 18px;
                strong {
                    font-size: 24px;
                }
            }
        }
        &-list {
            flex-wrap: wrap;
            li {
                width: 50%;
                h6 {
                    margin: 0 px2rem(20px);
                    font-size: 14px;
                }
                &:nth-child(3) {
                    background-color: #fafafa;
                }
                &:nth-child(4) {
                    background-color: #fff;
                }
            }
        }
        &-top {
            .br {
                display: block;
                strong:nth-child(1) {
                    margin-left: 0;
                }
            }
        }
        &-bottom {
            .system-swiper {
                .swiper-slide {
                    h6 {
                        font-size: 14px;
                    }
                }
            }
            .swiper-box {
                .swiper-button-prev,
                .swiper-button-next {
                    &::after {
                        font-size: 16px;
                    }
                }
            }
            .img-info {
                font-size: 14px;
                p {
                    line-height: 2;
                }
                .h4 {
                    font-size: 18px;
                }
            }
        }
    }
}
.introduce {
    overflow: hidden;
    margin-top: .5px;
    .article {
        font-size: 14px;
        p {
            line-height: 26px;
            color: #777;
        }
        .title {
            font-size: 24px;
            font-weight: 700;
            em {
                font-size: 36px;
                vertical-align: middle;
            }
        }
    }
    &-top {
        padding: px2rem(90px) 0 px2rem(120px);
        background-image: url(/public/assets/static/style/images/jj-bg1_02.jpg);
        background-position: top center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        .img-box {
            position: relative;
            box-sizing: border-box;
            width: 575px;
            height: 520px;
            border: 4px solid #f1b227;
            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 60px;
                max-width: 110%;
                max-height: 90%;
            }
        }
        .info-box {
            width: 660px;
            .h6 {
                padding-right: 20px;
                font-size: 18px;
                font-weight: 700;
                color: #456792;
            }
            .title {
                margin: px2rem(18px) 0 px2rem(10px);
                color: #49b8c2;
                em {
                    margin: 0 px2rem(6px);
                }
            }
        }
        &-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &-middle {
        padding: px2rem(160px) 0 px2rem(70px);
        background-image: url(/public/assets/static/style/images/jj-bg2_02.jpg);
        background-position: top center;
        background-repeat: no-repeat;
        background-color: #fff;
        .info-box {
            display: flex;
            justify-content: space-between;
            padding: 14px 90px 40px 70px;
            box-sizing: border-box;
            color: #fff;
            background-color: #418cb0;
            p {
                color: #fff;
            }
            .title {
                margin: px2rem(36px) 0 px2rem(18px);
                em {
                    margin-right: px2rem(12px);
                    font-size: 38px;
                    color: #ffa31f;
                }
            }
            &-left {
                width: 698px;
            }
            &-right {
                width: 425px;
                .title {
                    text-align: center;
                }
            }
        }
        .info-img {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: px2rem(70px) 0 0;
            .article {
                width: 680px;
                .title {
                    margin-bottom: 8px;
                    color: #3e89af;
                    em {
                        margin-right: 8px;
                        color: #ffa31f;
                    }
                }
            }
            img {
                max-width: 50%;
            }
        }
    }
    &-bottom {
        position: relative;
        box-sizing: border-box;
        padding: px2rem(75px) 0;
        height: 694px;
        background-image: url(/public/assets/static/style/images/jj-bg3_02.jpg);
        background-repeat: no-repeat;
        background-size: auto px2rem(694px);
        .article {
            position: absolute;
            left: 52%;
            transform: translateX(-50%);
            width: 1125px;
            color: #fff;
            p {
                margin-bottom: 13px;
                color: #fff;
            }
            .title {
                margin-bottom: px2rem(10px);
                em {
                    margin-right: px2rem(8px);
                    color: #ffa31f;
                }
            }
        }
    }
    @media (max-width: 1365px) {
        &-top {
            .img-box {
                margin-bottom: px2rem(20px);
                width: 100%;
                height: 0;
                padding-top: 60%;
                img {
                    width: 90%;
                    height: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .info-box {
                width: 100%;
                .h6 {
                    padding-right: 0;
                }
            }
            &-box {
                flex-wrap: wrap;
            }
        }
        &-middle {
            padding: px2rem(80px) 0;
            .info-box {
                flex-wrap: wrap;
                padding: 0 px2rem(60px) px2rem(40px);
                &-left,
                &-right {
                    width: 100%;
                }
                &-right {
                    .title {
                        text-align: left;
                    }
                }
            }
        }
        &-bottom {
            .article {
                margin: 0 .15rem;
                width: auto;
                left: 0;
                transform: translateX(0);
            }
        }
    }
    @media (max-width: 768px) {
        &-middle {
            .info-img {
                flex-wrap: wrap;
                .article {
                    width: 100%;
                }
                img {
                    margin-top: px2rem(30px);
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        &-bottom {
            padding-bottom: 100%;
            height: 0;
            background-size: auto 100%;
            background-position-x: 20%;
        }
    }
    @media (max-width: 750px) {
        .article {
            font-size: 13px;
            p {
                line-height: 22px;
            }
            .title {
                font-size: 18px;
                em {
                    font-size: 26px;
                }
            }
        }
        &-top {
            .info-box {
                .h6 {
                    font-size: 14px;
                }
            }
        }
        &-middle {
            .info-box {
                padding: 0 px2rem(40px) px2rem(30px);
            }
        }
        &-bottom {
            height: auto;
            padding-bottom: px2rem(50px);
            .article {
                position: static;
            }
        }
    }
}
.party {
    .article {
        font-size: 15px;
        color: #777;
        p {
            line-height: 2;
        }
        img {
            max-width: 100%;
        }
        .h4 {
            text-align: center;
            margin-bottom: px2rem(20px);
        }
        .h6 {
            font-size: 18px;
            text-align: center;
        }
    }
    .big-title {
        h2 {
            font-size: 48px;
            font-weight: 700;
            color: #333;
            text-align: center;
        }
        div {
            position: relative;
            margin-top: px2rem(24px);
            margin-bottom: px2rem(58px);
            transform: translateY(-50%);
            overflow: hidden;
            text-align: center;
            span {
                position: relative;
                padding: 0 px2rem(27px);
                font-size: 16px;
                color: #b8b8b8;
            }
            .line {
                position: absolute;
                top: 50%;
                width: 50%;
                border-bottom: 1px solid #b8b8b8;
                &:nth-child(1) {
                    left: 0;
                    transform: translate(-100%, -50%);
                }
                &:nth-child(2) {
                    right: 0;
                    transform: translate(100%, -50%);
                }
            }
        }
    }
    .h3 {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }
    .h4 {
        font-size: 26px;
        font-weight: 700;
        color: #333;
    }
    .num-box {
        font-size: 0;
        .num {
            display: inline-block;
            .h2 {
                h2 {
                    font-size: 60px;
                    font-weight: 700;
                }
            }
            .h6 {
                font-size: 16px;
            }
        }
    }
    &-1 {
        padding: px2rem(80px) 0 px2rem(70px);
        background-color: #fff;
        .img-box {
            margin-top: px2rem(40px);
            display: flex;
            justify-content: space-between;
            .h5 {
                margin-bottom: px2rem(16px);
                font-size: 24px;
                font-weight: 700;
            }
            .left {
                .h5 {
                    color: #f89b00;
                }
            }
            .right {
                .h5 {
                    color: #c00000;
                }
            }
        }
    }
    &-2 {
        padding: px2rem(80px) 0 px2rem(110px);
        .article {
            .h4 {
                margin-bottom: 4px;
            }
        }
        .flex-box {
            display: flex;
            justify-content: space-between;
            margin: px2rem(30px) 0 px2rem(78px);
            .img-div {
                width: percent(440, 1360);
                .img-box {
                    padding-top: percent(263, 440);
                    @include img();
                }
            }
        }
        .img-flex {
            display: flex;
            margin-top: px2rem(32px);
            box-shadow: 0 90px 30px -80px rgba($color: #000, $alpha: .2);
            li {
                width: percent(1, 3);
                display: flex;
                flex-wrap: wrap;
                background-color: #fff;
                .img-box,
                .info-box {
                    position: relative;
                    padding-top: percent(225, 453);
                    width: 100%;
                }
                .img-box {
                    @include img();
                }
                .info-box {
                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 20px;
                        color: #333;
                        text-align: center;
                        width: 55%;
                    }
                }
                @media (min-width: 750px) {
                    &:nth-child(2n) {
                        flex-direction: column-reverse;
                    }
                }
            }
        }
    }
    &-3 {
        padding: px2rem(80px) 0 px2rem(100px);
        background-image: url(/public/assets/static/style/images/dq-bg1_02.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: #fff;
        text-align: center;
        .h4 {
            color: #fff;
        }
        .h5 {
            font-size: 20px;
            font-weight: 700;
        }
        .icon-box {
            margin: px2rem(80px) 0 px2rem(40px);
            width: auto;
            font-size: 0;
            .icon {
                display: inline-block;
                margin: 0 30px;
                em {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                    background-color: #f3dabe;
                    border-radius: 50%;
                    font-size: 36px;
                    color: #c00000;
                }
                .h6 {
                    margin-top: px2rem(10px);
                    font-size: 18px;
                }
            }
            img {
                width: px2rem(86px);
                height: px2rem(8px);
                margin-top: 46px;
            }
            img:last-child {
                display: none;
            }
        }
        .num-box {
            margin: px2rem(60px) auto px2rem(50px);
            .num {
                margin: 0 px2rem(60px);
            }
        }
        .img-box {
            display: flex;
            li {
                //width: percent(1, 3);
                flex: 1;
                //padding-top: percent(483, 1360);
                //@include img();
                background-color: #c00000;
                .img {
                    //position: relative;
                    width: 100%;
                    padding-top: percentage(375/184);
                    @include img();
                }
                .info {
                    //position: absolute;
                    //left: 0;
                    //right: 0;
                    //bottom: 0;
                    padding: px2rem(18px) 0;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: #fff;
                    background-color: #c00000;
                }
            }
        }
    }
    &-4 {
        padding: px2rem(70px) 0 px2rem(60px);
        background-color: #fff;
        .w1360 {
            display: flex;
            justify-content: space-between;
            img {
                max-width: 100%;
            }
        }
        .h4 {
            margin-bottom: px2rem(20px);
        }
    }
    &-5 {
        padding: px2rem(75px) 0 px2rem(105px);
        background-image: url(/public/assets/static/style/images/dq-bg2_06.jpg);
        background-size: cover;
        text-align: center;
        img {
            margin: 32px 0;
            padding-left: 140px;
        }
        .h3 {
            font-size: 30px;
            font-weight: 400;
            color: #333;
            span {
                color: #db2d19;
            }
        }
    }
    &-6 {
        padding: px2rem(100px) 0 px2rem(125px);
        background-image: url(/public/assets/static/style/images/dq-bg3_02.jpg);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100% auto;
        text-align: center;
        background-color: #4a0511;
        color: #fff;
        .h4 {
            color: #fff;
        }
        .tit1 {
            display: inline-flex;
            margin: px2rem(50px) auto px2rem(30px);
            li {
                margin: 0 27px;
                width: 136px;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                font-weight: 700;
                border-radius: 1px;
                background-color: #ad5150;
                cursor: pointer;
            }
            li:hover,
            .on {
                background-color: #b6201f;
            }
        }
        .box1 {
            .item {
                padding: px2rem(45px) px2rem(50px) px2rem(80px);
                background-color: #fff;
            }
            .img-div {
                display: flex;
                flex-wrap: wrap;
            }
            .img-box {
                margin: 5px;
                width: 410px;
                padding-top: 225px;
                @include img();
                .h4 {
                    position: absolute;
                    bottom: px2rem(12px);
                    width: 100%;
                    text-align: center;
                    color: #fff;
                }
            }
            .mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url(/public/assets/static/style/images/dq-mask_03.png);
                background-size: cover;
                background-repeat: no-repeat;
            }
            .h5 {
                margin-top: px2rem(35px);
                font-size: 18px;
                font-weight: 700;
                color: #333;
                span {
                    color: #b6201f;
                }
            }
        }
        .big-title {
            margin-top: px2rem(100px);
            h2 {
                color: #fff;
            }
            div {
                span {
                    color: #fff;
                }
                .line {
                    border-bottom: 1px solid rgba($color: #fff, $alpha: .2);
                }
            }
        }
        .p {
            width: 1050px;
            max-width: 100%;
            font-size: 16px;
            line-height: 30px;
            margin: 0 auto;
        }
        .num-box {
            margin: px2rem(40px) 0 px2rem(80px);
            .num {
                margin: 0 px2rem(80px);
            }
        }
        .diff-box {
            .tit-s {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: px2rem(40px);
                li {
                    width: percent(380, 1150);
                    font-size: 18px;
                    margin-left: 5px;
                    padding: px2rem(18px) 0;
                    background-color: #757575;
                    border-radius: 2px;
                    cursor: pointer;
                    &:first-child {
                        margin-left: 0;
                    }
                }
                .on {
                    background-color: #b6201f;
                }
            }
            .box-s {
                .table {
                    display: flex;
                    margin-bottom: px2rem(20px);
                    background-color: #fae1c7;
                    &-left {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: percent(300, 1150);
                        border-right: 1px solid rgba($color: #000, $alpha: .1);
                        h4,
                        h6 {
                            width: 100%;
                            text-align: center;
                        }
                        h4 {
                            font-size: 30px;
                            color: #000;
                        }
                        h6 {
                            font-size: 14px;
                            color: #000;
                        }
                    }
                    &-right {
                        width: percent(850, 1150);
                        font-size: 0;
                        text-align: left;
                        span {
                            display: inline-block;
                            box-sizing: border-box;
                            padding: px2rem(20px) 0 px2rem(20px) px2rem(135px);
                            width: 50%;
                            font-size: 16px;
                            color: #000;
                            border-bottom: 1px solid rgba($color: #000, $alpha: .1);
                        }
                    }
                }
            }
        }
    }
    &-7 {
        padding: px2rem(120px) 0 px2rem(110px);
        background-image: url(/public/assets/static/style/images/dq-bg4_02.jpg);
        background-repeat: no-repeat;
        background-position: top center;
        .h3 {
            text-align: center;
        }
    }
    .diff-box {
        margin-top: px2rem(35px);
        padding: 35px 105px 95px;
        background-color: #fff;
        .h3 {
            color: #333;
        }
        .itit {
            display: flex;
            justify-content: center;
            margin-top: px2rem(24px);
            li {
                position: relative;
                font-size: 18px;
                color: #000;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -6px;
                    width: 1px;
                    height: 12px;
                    background-color: #d9d9d9;
                }
            }
            li:first-child {
                &::before {
                    display: none;
                }
            }
            span {
                display: block;
                padding: px2rem(14px) px2rem(25px);
                margin: 0 px2rem(32px);
                border-radius: 2px;
                cursor: pointer;
            }
            .on {
                color: #fff;
                span {
                    background-color: #b6201f;
                }
            }
        }
        .ibox {
            margin-top: px2rem(40px);
            h5 {
                margin-top: px2rem(24px);
                font-size: 18px;
                font-weight: 700;
                color: #333;
                text-align: center;
            }
        }
        .swiper-box {
            position: relative;
            .swiper-button-prev,
            .swiper-button-next {
                outline: none;
                width: auto;
                height: auto;
                &::after {
                    content: "\e64e";
                    font-family: "iconfont";
                    font-size: 45px;
                    color: #666;
                }
            }
            .swiper-button-prev {
                left: -20px;
                transform: translateX(-100%) rotate(-90deg);
            }
            .swiper-button-next {
                right: -20px;
                transform: translateX(100%) rotate(90deg);
            }
        }
        .swiper-slide {
            padding-top: percent(365, 1150);
            @include img();
            h4 {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: px2rem(16px);
                text-align: center;
                font-size: 24px;
                color: #fff;
                background-color: #b6201f;
            }
        }
    }
    @media (max-width: 1580px) {
        &-6 {
            background-size: auto 75%;
        }
    }
    @media (max-width: 1365px) {
        &-1 {
            .left {
                margin-right: 20px;
            }
        }
        &-3 {
            .num-box {
                .num {
                    margin: 0 px2rem(20px);
                }
            }
            .img-box {
                li {
                    .info {
                        font-size: 16px;
                    }
                }
            }
        }
        &-4 {
            .w1360 {
                div:nth-child(1) {
                    margin-right: 10px;
                }
            }
        }
        &-5 {
            img {
                box-sizing: border-box;
                max-width: 100%;
                padding: 0 px2rem(30px);
            }
        }
        &-6 {
            .box1 {
                .img-div {
                    justify-content: space-between;
                }
                .img-box {
                    width: 49%;
                    padding-top: percent(225, 800);
                    margin: 0;
                    margin-bottom: 2%;
                }
            }
            .diff-box {
                .tit-s {
                    li {
                        width: 32%;
                        font-size: 16px;
                    }
                }
                .box-s {
                    .table {
                        &-right {
                            span {
                                padding-left: px2rem(40px);
                            }
                        }
                    }
                }
            }
        }
        .diff-box {
            padding: px2rem(35px) px2rem(50px) px2rem(95px);
            .itit {
                li {
                    font-size: 16px;
                }
                span {
                    padding: px2rem(12px) px2rem(20px);
                    margin: 0 px2rem(15px);
                }
            }
            .swiper-box {
                .swiper-button-prev,
                .swiper-button-next {
                    &::after {
                        font-size: 40px;
                    }
                }
                .swiper-button-prev {
                    left: -5px;
                }
                .swiper-button-next {
                    right: -5px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .big-title {
            h2 {
                font-size: px2rem(46px);
            }
        }
        .num-box {
            .num {
                .h2 {
                    h2 {
                        font-size: px2rem(48px);
                    }
                }
                .h6 {
                    font-size: 14px;
                }
            }
        }
        &-3 {
            .icon-box {
                .icon {
                    margin: 0 20px;
                    em {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                    }
                }
                img {
                    margin-top: 26px;
                }
            }
            .img-box {
                flex-wrap: wrap;
                li {
                    flex: 0 1 50%;
                    //width: 50%;
                    //padding-top: percent(480, 900);
                }
                //li:nth-child(3){
                //    width: 100%;
                //    padding-top: percent(480, 450);
                //}
            }
        }
        &-4 {
            .w1360 {
                flex-wrap: wrap;
                div {
                    width: 100%;
                    margin-top: px2rem(20px);
                    margin-left: 0;
                }
                img {
                    width: 100%;
                }
            }
        }
        &-6 {
            .tit1 {
                li {
                    margin: 0 px2rem(20px);
                }
            }
            .box1 {
                .item {
                    padding: px2rem(40px);
                }
            }
        }
    }
    @media (max-width: 750px) {
        .article {
            font-size: 14px;
            .h6 {
                font-size: 15px;
            }
        }
        .big-title {
            div {
                transform: translateY(0);
                span {
                    font-size: 14px;
                    padding: 0;
                }
                .line {
                    display: none;
                }
            }
        }
        .h3 {
            font-size: 18px;
            box-sizing: border-box;
            padding: 0 .15rem;
        }
        .h4 {
            font-size: 18px;
        }
        &-1 {
            .img-box {
                flex-wrap: wrap;
                .h5 {
                    font-size: 18px;
                }
                .left {
                    margin-right: 0;
                    width: 100%;
                }
                .right {
                    margin-top: px2rem(16px);
                    width: 100%;
                }
            }
        }
        &-2 {
            .flex-box {
                flex-wrap: wrap;
                .img-div {
                    margin: px2rem(10px) 0;
                    width: 100%;
                }
            }
            .img-flex {
                flex-wrap: wrap;
                box-shadow: 0 30px 10px -20px rgba($color: #000, $alpha: .2);
                li {
                    width: 100%;
                    .info-box {
                        padding-top: 25%;
                        span {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &-3 {
            background-size: 100% 100%;
            .h5 {
                font-size: 18px;
            }
            .icon-box {
                .icon {
                    margin: 0 10px;
                    em {
                        width: 50px;
                        height: 50px;
                    }
                    .h6 {
                        font-size: 14px;
                    }
                }
                img {
                    display: none;
                }
            }
            .num-box {
                margin-top: 10px;
                .num {
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    border-bottom: 1px solid rgba($color: #fff, $alpha: .2);
                    .h2 {
                        width: 80px;
                        h2 {
                            font-size: 30px;
                        }
                    }
                }
            }
            .img-box {
                li {
                    //padding-top: percent(480, 450);
                    width: 100%;
                    flex: 0 1 100%;
                    .img {
                        padding-top: percentage(483/453);
                    }
                }
            }
        }
        &-6 {
            .tit1 {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin: 0 1% 1%;
                    width: 48%;
                }
            }
            .box1 {
                .item {
                    padding: px2rem(20px);
                }
                .img-box {
                    .h4 {
                        font-size: 12px;
                    }
                }
            }
            .diff-box {
                .tit-s {
                    li {
                        width: 100%;
                        font-size: 14px;
                        margin-left: 0;
                        margin-top: 5px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
                .box-s {
                    .table {
                        flex-wrap: wrap;
                        &-left,
                        &-right {
                            width: 100%;
                        }
                        &-left {
                            padding: px2rem(20px);
                        }
                        &-right {
                            span {
                                width: 100%;
                                padding-left: 0;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        &-7 {
            background-size: auto 50%;
        }
        .diff-box {
            padding: px2rem(35px) px2rem(20px) px2rem(80px);
            .itit {
                flex-wrap: wrap;
                li {
                    font-size: 14px;
                    &::before {
                        margin-top: -4px;
                        height: 8px;
                    }
                }
                span {
                    margin: 0;
                }
            }
            .ibox {
                h5 {
                    font-size: 16px;
                }
            }
            .swiper-box {
                .swiper-button-prev,
                .swiper-button-next {
                    &::after {
                        font-size: 24px;
                        color: #fff;
                    }
                }
                .swiper-button-prev {
                    left: px2rem(10px);
                    transform: translateX(0) rotate(-90deg);
                }
                .swiper-button-next {
                    right: px2rem(10px);
                    transform: translateX(0) rotate(90deg);
                }
            }
            .swiper-slide {
                padding-top: percent(365, 570);
                h4 {
                    font-size: 15px;
                }
            }
        }
    }
}
