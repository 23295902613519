.framework {
    margin-bottom: .5px;
    padding: px2rem(60px) 0;
    img {
        max-width: 100%;
        background-color: #f5f5f5;
    }
}
//点击图片放大
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}
.contact {
    padding: px2rem(80px) 0 px2rem(90px);
    &-box {
        display: flex;
        padding: px2rem(45px);
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0 15px 20px -10px rgba($color: #000, $alpha: .1);
    }
    .addr {
        width: percent(450, 1270);
        h3 {
            display: inline-block;
            margin: 50px 0 15px;
            padding-bottom: 15px;
            font-size: 34px;
            font-weight: 700;
            border-bottom: 1px dashed #b3b3b3;
        }
        p {
            font-size: 16px;
            line-height: 36px;
            color: #666;
        }
    }
    .map-box {
        position: relative;
        padding-top: percent(370, 1270);
        width: percent(820, 1270);
        #map {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
        }
    }
    @media (max-width: 1365px) {
        .addr {
            margin-right: 20px;
            h3 {
                display: block;
                margin: px2rem(10px) 0 px2rem(20px);
                padding-bottom: px2rem(20px);
                font-size: 20px;
            }
            p {
                font-size: 14px;
                line-height: 2;
            }
        }
    }
    @media (max-width: 768px) {
        &-box {
            flex-wrap: wrap;
        }
        .addr {
            margin-right: 0;
            margin-bottom: px2rem(30px);
            width: 100%;
            h3 {
                font-size: px2rem(36px);
            }
        }
        .map-box {
            width: 100%;
            padding-top: 50%;
        }
    }
}
.idea {
    padding-top: px2rem(60px);
    padding-bottom: px2rem(110px);
    &-content {
        display: flex;
        background-color: #fff;
    }
    &-item {
        box-sizing: border-box;
        padding: px2rem(65px) px2rem(29px) px2rem(20px);
        border-left: 2px solid #f5f5f5;
        width: percent(1, 6);
        text-align: center;
        border-bottom: 2px solid #f5f5f5;
        em {
            display: block;
            font-size: 42px;
            color: #348fc7;
            transition: all .3s;
        }
        h4 {
            margin-top: px2rem(30px);
            font-size: 20px;
            font-weight: 700;
            color: #555;
        }
        h6 {
            margin: px2rem(5px) 0 px2rem(20px);
            font-size: 12px;
            font-family: Arial;
            color: #c0c0c0;
        }
        p {
            font-size: 15px;
            line-height: 30px;
            color: #888;
        }
        &:hover {
            em {
                transform: rotateY(180deg);
            }
        }
    }
    @media (max-width: 1365px) {
        &-content {
            flex-wrap: wrap;
        }
        &-item {
            width: percent(1, 3);
            border-top: 2px solid #f5f5f5;
        }
    }
    @media (max-width: 750px) {
        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            em,
            h4,
            h6,
            p {
                width: 100%;
            }
        }
    }
}
.atlas {
    margin-bottom: .5px;
    padding-bottom: px2rem(70px);
    &-list {
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        display: block;
        margin: 0 percent(44, 1360) percent(50, 1360) 0;
        width: percent(307, 1360);
        .img-box {
            position: relative;
            padding-top: percent(358, 307);
            background-color: #fff;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 80%;
                max-height: 80%;
                transition: all .3s ease;
            }
        }
        h6 {
            margin-top: px2rem(22px);
            font-size: 16px;
            color: #000;
            text-align: center;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:hover {
            .img-box {
                img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }
    }
    @media (max-width: 750px) {
        &-item {
            width: 49%;
            margin: 0 2% 2% 0;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}
.honor {
    min-height: 600px;
    padding: px2rem(100px) 0 px2rem(90px);
    background-image: url(/public/assets/static/style/images/ry-bg_02.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    .paged {
        padding-top: px2rem(20px);
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        box-sizing: border-box;
        margin: 0 percent(25, 1360) percent(30, 1360) 0;
        width: percent(252, 1360);
        background-color: #fff;
        border: 1px solid #d3d3d3;
        box-shadow: 0 25px 30px -30px rgba($color: #000, $alpha: .5);
        cursor: pointer;
        h6 {
            margin: 0 40px;
            font-size: 14px;
            line-height: 20px;
            min-height: 40px;
            color: #555;
            text-align: center;
        }
        .img-box {
            padding-top: percent(180, 252);
            position: relative;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 90%;
                max-height: 90%;
                transition: all .3s ease;
            }
        }
        @media (min-width: 769px) {
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        &:hover {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
    &-btn {
        margin: 10px auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $hoverColor;
        color: #fff;
        font-size: 14px;
    }
    // 弹框
    .honor-pop {
        display: none;
        position: fixed;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        .pop-con {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 920px;
            height: 800px;
            background-color: #fff;
            border-radius: 4px;
        }
        .box {
            padding: 70px 80px 0;
        }
        .box-img {
            position: relative;
            padding-top: (560/740) * 100%;
            background: #f5f5f3;
            img {
                position: absolute;
                //width: 100%;
                top: 50%;
                left: 50%;
                max-width: 100%;
                max-height: 100%;
                transform: translate(-50%, -50%);
            }
        }
        .h4 {
            display: block;
            padding: 20px 0;
            margin-bottom: 15px;
            font-size: 24px;
            color: #444;
            text-align: center;
        }
        .pop-close {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: -60px;
            z-index: 1;
            width: 60px;
            height: 60px;
            line-height: 60px;
            background-color: #006daf;
            color: #fff;
            font-size: 30px;
            text-align: center;
            &:hover {
                opacity: .95;
            }
        }
    }
    @media screen and (max-height: 800px), (max-width: 950px) {
        .honor-pop {
            .pop-con {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
            .box {
                padding: 70px 10px 0;
            }
            .box-img {
                position: absolute;
                top: 70px;
                left: 10px;
                right: 10px;
                bottom: 150px;
                padding-top: 0;
            }
            .h4 {
                position: absolute;
                left: 10px;
                right: 10px;
                bottom: 50px;
                font-size: px2rem(38px);
            }
            .pop-close {
                top: 0;
                right: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 22px;
            }
        }
    }
    @media (max-width: 1365px) {
        min-height: px2rem(400px);
        &-item {
            h6 {
                margin: 0 20px;
            }
        }
    }
    @media (max-width: 768px) {
        &-item {
            margin: 0 2% 4% 0;
            width: 49%;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}
.video {
    margin-bottom: .5px;
    padding-bottom: px2rem(60px);
    .play {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: px2rem(20px);
        bottom: px2rem(20px);
        width: px2rem(40px);
        height: px2rem(40px);
        border: px2rem(2px) solid #fff;
        border-radius: 50%;
        &::before {
            content: "\e803";
            font-family: "iconfont";
            color: #fff;
            font-size: 16px;
        }
    }
    &-main {
        padding-bottom: px2rem(70px);
        background-image: url(/public/assets/static/style/images/sp-bg_03.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: auto px2rem(634px);
        h4 {
            text-align: center;
            margin-top: px2rem(20px);
            font-size: 26px;
            font-weight: 700;
            color: #fff;
        }
    }
    &-list {
        margin: px2rem(40px) 0 px2rem(30px);
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        margin-right: percent(44, 1360);
        width: percent(424, 1360);
        h4 {
            margin: px2rem(20px) 0 px2rem(30px);
            font-size: 20px;
            color: #000;
            text-align: center;
        }
        @media (min-width: 769px) {
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
    &-big {
        margin: 0 auto;
        padding-top: 514px;
        width: 918px;
        max-width: 100%;
        cursor: pointer;
        @include img();
        .mask {
            background-image: url(/public/assets/static/style/images/sp-mb_03.png);
        }
        .play {
            left: px2rem(30px);
            bottom: px2rem(30px);
        }
    }
    &-small {
        padding-top: percent(237, 424);
        cursor: pointer;
        @include img();
    }
    @media (max-width: 1024px) {
        &-big {
            width: 90%;
            padding-top: percent(514 * .9, 918);
        }
    }
    @media (max-width: 768px) {
        .play {
            &::before {
                font-size: 12px;
            }
        }
        &-item {
            margin-right: 2%;
            width: 49%;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
    @media (max-width: 750px) {
        &-main {
            h4 {
                font-size: 18px;
            }
        }
        &-item {
            h4 {
                font-size: 14px;
            }
        }
    }
}
.history {
    margin-bottom: .5px;
    padding: px2rem(110px) 0 px2rem(136px);
    &-box {
        position: relative;
    }
    &-sjz {
        position: absolute;
        top: 0;
        left: 50%;
        bottom: px2rem(45px);
        transform: translate(-50%, 0);
        width: px2rem(36px);
        background-image: url(/public/assets/static/style/images/lc-sjz_03.jpg);
        background-size: 100% auto;
    }
    .icon:nth-of-type(1) {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -92%);
        width: px2rem(80px);
    }
    .icon:nth-of-type(2) {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        width: px2rem(56px);
        cursor: pointer;
    }
    &-info {
        float: right;
        clear: right;
        width: percent(665, 1500);
        h2,
        h3 {
            font-weight: 700;
            color: #333;
            opacity: .1;
        }
        h2 {
            font-size: px2rem(80px);
        }
        h3 {
            font-size: px2rem(88px);
            font-family: Arial;
        }
    }
    &-li {
        position: relative;
        box-sizing: border-box;
        padding: px2rem(80px) px2rem(45px) px2rem(60px);
        margin-top: px2rem(65px);
        width: percent(680, 1500);
        background-color: #fff;
        h3 {
            margin-bottom: px2rem(25px);
            font-size: px2rem(36px);
            font-weight: 700;
            color: #0082d6;
        }
        p {
            position: relative;
            font-size: 18px;
            line-height: 36px;
            color: #333;
            padding-left: 20px;
            &::before {
                content: "";
                position: absolute;
                top: 13px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid #f49d0d;
                box-sizing: border-box;
            }
        }
        img {
            max-width: 100%;
            margin-bottom: px2rem(30px);
        }
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            width: 0;
            height: 0;
            border: px2rem(30px) solid transparent;
        }
    }
    &-li:nth-child(2n) {
        float: left;
        clear: left;
        h3 {
            text-align: right;
        }
        &::before {
            right: 0;
            transform: translate(100%, -50%);
            border-left: px2rem(30px) solid #fff;
        }
    }
    &-li:nth-child(2n+1) {
        float: right;
        clear: right;
        &::before {
            left: 0;
            transform: translate(-100%, -50%);
            border-right: px2rem(30px) solid #fff;
        }
    }
    @media (max-width: 1560px) {
        &-li {
            padding: px2rem(60px) px2rem(40px) px2rem(40px);
        }
    }
    @media (max-width: 1365px) {
        &-sjz {
            left: px2rem(20px);
            transform: translate(-50%, 0);
        }
        .icon:nth-of-type(1) {
            left: px2rem(20px);
            transform: translate(-50%, -92%);
        }
        .icon:nth-of-type(2) {
            left: px2rem(20px);
            transform: translate(-50%, 0);
        }
        &-info {
            width: 90%;
        }
        &-li {
            width: 90%;
        }
        &-li:nth-child(2n) {
            float: right;
            clear: right;
            h3 {
                text-align: left;
            }
            &::before {
                left: 0;
                transform: translate(-100%, -50%);
                border-left: 0;
                border-right: px2rem(30px) solid #fff;
            }
        }
    }
    @media (max-width: 750px) {
        &-li {
            p {
                padding-left: 16px;
                font-size: 14px;
                line-height: 2;
                &::before {
                    top: 9px;
                }
            }
        }
    }
}
.recruit {
    margin-bottom: .5px;
    padding-bottom: px2rem(50px);
    &-item {
        margin-bottom: px2rem(20px);
    }
    &-title {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 17px 40px 17px 100px;
        font-size: 17px;
        color: #333;
        background-color: #fff;
        border: 1px solid #efefef;
        li:nth-child(1) {
            width: percent(240, 1220);
        }
        li:nth-child(2) {
            width: percent(330, 1220);
        }
        li:nth-child(3) {
            width: percent(220, 1220);
        }
        li:nth-child(4) {
            flex: 1;
        }
        li:nth-child(5) {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: #787c81;
            border: 2px solid #787c81;
            background-color: #fff;
            cursor: pointer;
            &::before {
                transition: all .3s ease;
                transform: rotate(90deg);
                font-size: 13px;
            }
        }
    }
    &-content {
        margin-top: .68px;
        display: none;
        padding: px2rem(70px) 0 px2rem(125px);
        background-color: #fff;
        .article {
            display: flex;
            flex-wrap: wrap;
            font-size: 15px;
            p {
                line-height: 36px;
                color: #666;
            }
            .h3 {
                margin-bottom: px2rem(20px);
                font-size: 26px;
                font-weight: 700;
                color: 444;
            }
            &-left {
                position: relative;
                box-sizing: border-box;
                padding: 0 percent(75, 1360) 0 percent(85, 1360);
                width: percent(660, 1360);
                .bottom-btn {
                    position: absolute;
                    bottom: -70px;
                    width: 100%;
                    //left: 0;
                    display: flex;
                    .tips {
                        line-height: 40px;
                        margin-left: 10px;
                        font-size: 14px;
                        color: red;
                    }
                }
                .form {
                    position: absolute;
                    top: 0;
                    left: 0;
                    //transform: translateY(100%);
                    color: #666;
                    input[type=file] {
                        opacity: 0;
                        width: 150px;
                        height: 40px;
                        cursor: pointer;
                    }
                }
                .more {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //position: absolute;
                    //bottom: -20px;
                    //transform: translateY(100%);
                    width: 150px;
                    height: 40px;
                    background-color: #f09c49;
                    border-radius: 20px;
                    font-size: 15px;
                    color: #fff;
                    &:hover {
                        background-color: $hoverColor;
                    }
                }
            }
            &-right {
                box-sizing: border-box;
                padding: 0 percent(60, 1360);
                width: percent(700, 1360);
                border-left: 1px dashed rgba($color: #000, $alpha: .56);
            }
        }
    }
    .on {
        background-color: #0072b8;
        color: #fff;
        li:nth-child(5) {
            border: 2px solid #fff;
            &::before {
                transform: rotate(-90deg);
            }
        }
    }
    .on + &-content {
        display: block;
    }
    @media (max-width: 768px) {
        &-title {
            li:nth-child(1) {
                width: 40%;
            }
            li:nth-child(2) {
                width: 55%;
            }
            li:nth-child(3),
            li:nth-child(4) {
                display: none;
            }
        }
    }
    @media (max-width: 750px) {
        &-title {
            padding: px2rem(20px) px2rem(40px);
            font-size: 14px;
            li:nth-child(5) {
                width: 24px;
                height: 24px;
                &::before {
                    font-size: 12px;
                }
            }
        }
        &-content {
            .article {
                position: relative;
                font-size: 13px;
                p {
                    line-height: 2;
                }
                .h3 {
                    font-size: 16px;
                }
                &-left,
                &-right {
                    padding: 0 px2rem(30px);
                    width: 100%;
                }
                &-left {
                    position: static;
                    .form {
                        input[type=file] {
                            width: 100px;
                            height: 30px;
                        }
                    }
                    .more {
                        width: 100px;
                        height: 30px;
                        border-radius: 15px;
                    }
                }
                &-right {
                    margin-top: px2rem(20px);
                    border-left: 0;
                }
            }
        }
    }
}
.map {
    margin-bottom: .5px;
    padding: px2rem(70px) 0 px2rem(90px);
    //地区分布
    $mainColor: #f5932a;
    @mixin lineColor($width) {
        width: $width;
        position: absolute;
        height: 2px;
        background-color: #fff;
        overflow: hidden;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            height: 2px;
            background-color: #014a8f;
            content: '';
            width: calc-percent(3, 5);
            display: block;
        }
        &:after {
            position: absolute;
            right: 0;
            top: 0;
            height: 2px;
            background-color: #e60012;
            content: '';
            width: calc-percent(1.5, 5);
            display: block;
        }
    }
    .wp1170 {
        width: 1360px;
        margin: 0 auto;
        @media screen and (max-width: 1365px) {
            margin: 0 px2rem(30px);
            width: auto;
        }
    }
    .map-info-con {
        //padding-top: 30px;
        //background-color: #fff;
        overflow: hidden;
    }
    .map-intro {
        margin: 0 auto;
        width: 1677px;
        max-width: 100%;
        position: relative;
        background-color: #f5f5f5;
        padding-top: px2rem(30px);
        padding-bottom: 105px;
        overflow: hidden;
        background-image: url(/public/assets/static/style/images/mapbg.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width: 750px) {
            background-size: 0;
            padding-bottom: 80px;
        }
    }
    .map-intro-word {
        position: relative;
        float: left;
        width: 430px;
        padding: 90px 0 25px 5px;
        .cn {
            font-size: 34px;
            color: #010101;
            line-height: 38px;
            margin-bottom: 5px;
        }
        .en {
            font-size: 55px;
            color: #272727;
            line-height: 60px;
            //text-transform: uppercase;
            margin-bottom: 15px;
            font-family: 'SourceHanSerifCN', Arial;
            font-weight: bold;
        }
        .p {
            font-size: 12px;
            color: #929292;
            line-height: 21px;
        }
        .line {
            @include lineColor(50px);
            left: 5px;
            bottom: 0;
        }
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    .map-intro-area {
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            float: none;
            margin: 0 auto;
        }
        @media screen and (max-width: 750px) {
            display: none;
        }
    }
    .map-info-select {
        padding-top: 35px;
        .map-select-wp select,
        .map-input-wp input[type="text"],
        .map-input-submit {
            height: 44px;
            line-height: 44px;
        }
        @media screen and (max-width: 750px) {
            padding-top: 0;
        }
    }
    .map-area-con {
        box-sizing: border-box;
        margin-top: -68px;
        position: relative;
        z-index: 2;
        background-color: #fff;
        padding: px2rem(35px);
        display: flex;
        border: 1px solid #eaeaea;
        @media screen and (max-width: 750px) {
            display: block;
        }
    }
    .map-area-province {
        font-size: 22px;
        color: #272727;
        line-height: 30px;
        padding-right: 25px;
        &:before {
            content: '\e61c';
            margin-right: 7px;
        }
        @media screen and (max-width: 750px) {
            margin-bottom: 15px;
        }
    }
    .map-area-city {
        flex: 1;
        border-left: 1px solid #e0e0e0;
        padding-left: 27px;
        @media screen and (max-width: 750px) {
            border-left: none;
            padding-left: 0;
        }
        a {
            display: inline-block;
            vertical-align: top;
            height: 23px;
            border: 1px solid #e5e5e5;
            padding: 0 12px;
            background: #f9f9f9;
            margin: 0 11px 11px 0;
            font-size: 13px;
            color: #272727;
            cursor: pointer;
            line-height: 23px;
            border-radius: 2px;
            transition: all .2s;
            &:hover,
            &.on {
                border-color: $mainColor;
                background: $mainColor;
                color: #fefefe;
            }
        }
    }
    .map-area-list {
        margin-top: px2rem(40px);
        .flex-ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-right: 20px;
            margin-bottom: 16px;
            width: 440px;
            background-color: #fff;
            .lit {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: px2rem(16px) px2rem(30px);
                border-bottom: 1px solid rgba($color: #000, $alpha: .1);
                h4 {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 700;
                    color: #333;
                }
                h5 {
                    font-size: 18px;
                    color: #333;
                }
                em {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    width: 60px;
                    height: 60px;
                    background-color: #f5932a;
                    border-radius: 50%;
                    font-size: 30px;
                    font-weight: 400;
                    color: #fff;
                }
            }
            .lip {
                box-sizing: border-box;
                min-height: 250px;
                padding: px2rem(20px) px2rem(35px);
                font-size: 14px;
                p {
                    line-height: 28px;
                    color: #777;
                }
            }
            @media screen and (min-width: 1366px) {
                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 1365px) {
            li {
                width: 49%;
                margin-right: 2%;
                .lip {
                    min-height: 0;
                }
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
        @media screen and (max-width: 750px) {
            li {
                width: 100%;
                .lit {
                    h4 {
                        font-size: 18px;
                    }
                    h5 {
                        font-size: 16px;
                    }
                    em {
                        width: 40px;
                        height: 40px;
                        font-size: 20px;
                    }
                }
                &:nth-child(n) {
                    margin-right: 0;
                }
            }
        }
    }
    .sale-thumb-swiper {
        width: 112px * 3;
        max-width: 100%;
        margin: 20px 0 0;
        .swiper-slide {
            width: auto !important;
            position: relative;
            overflow: hidden;
            cursor: zoom-in;
            background-color: #000;
            .img {
                position: relative;
                width: 110px;
                height: 79px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                @media screen and (max-width: 750px) {
                    width: 82px;
                    height: 59px;
                }
            }
            &.is-video {
                &:before {
                    position: absolute;
                    display: block;
                    width: 24px;
                    height: 24px;
                    background-image: url(/public/assets/static/style/maps/images/play-icon.png);
                    background-size: 100% 100%;
                    content: '';
                    z-index: 2;
                    left: 10px;
                    bottom: 10px;
                    @media screen and (max-width: 750px) {
                        width: 16px;
                        height: 16px;
                        left: 5px;
                        bottom: 5px;
                    }
                }
            }
            &:hover {
                .img {
                    opacity: .8;
                }
            }
        }
    }
    .sale-dialog {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9909;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        display: none;
    }
    .sale-dialog-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 762px;
        height: 572px;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        padding: 60px 70px;
        z-index: 5;
        background-color: #fff;
        border-radius: 5px;
        .sale-dialog-close {
            position: absolute;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            border: 1px solid #bebebe;
            border-radius: 100%;
            color: #bebebe;
            font-size: 20px;
            top: 20px;
            right: 20px;
            z-index: 2;
            cursor: pointer;
            transition: all .2s;
            &:hover {
                transform: rotate(45deg);
                border-color: #c99c52;
                color: #c99c52;
            }
        }
        .sale-dialog-arrow {
            position: absolute;
            z-index: 5;
            width: 100%;
            left: 0;
            text-align: center;
            font-size: 0;
            bottom: -28px;
            > div {
                display: inline-block;
                vertical-align: top;
                width: 94px;
                height: 62px;
                text-align: center;
                line-height: 62px;
                color: #fff;
                font-size: 20px;
                cursor: pointer;
                user-select: none;
                outline: none;
                transition: background-color .2s;
            }
            .sale-dialog-prev {
                background-color: #c99c52;
                &:hover {
                    background-color: lighten(#c99c52, 5%);
                }
            }
            .sale-dialog-next {
                background-color: #d0aa6c;
                &:hover {
                    background-color: lighten(#d0aa6c, 5%);
                }
            }
        }
        @media screen and (max-width: 750px) {
            width: 100%;
            height: 100%;
            background-color: #000;
            padding: 0;
            .sale-dialog-arrow {
                bottom: 0;
                > div {
                    width: 70px;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
    }
    .sale-dialog-swiper {
        height: 452px;
        .swiper-slide {
            width: 100%;
            height: 100%;
            position: relative;
            .img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-size: cover;
                background-position: center center;
            }
            .video-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                top: 0;
                left: 0;
                display: none;
                &:before {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    background-image: url(/public/assets/static/style/maps/images/play-icon.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    display: block;
                    top: 50%;
                    left: 50%;
                    margin: -20px 0 0 -20px;
                    z-index: 5;
                    content: '';
                }
            }
            .video-box {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 3;
            }
            .swiper-video {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                user-select: none;
                outline: none;
                background-color: #000;
            }
            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 5;
                transform: translate(-50%, -50%);
            }
        }
        @media screen and (max-width: 750px) {
            height: 100vh;
            .swiper-slide {
                .video-box {
                    top: 50px;
                    bottom: 50px;
                    height: auto;
                }
            }
        }
    }
    .map-box {
        position: relative;
        width: 640px;
        height: 490px;
        background: url(/public/assets/static/style/images/map_bg_2.png) center no-repeat;
        > * {
            display: block;
            position: absolute;
            z-index: 1;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        .ah {
            width: 52px;
            height: 63px;
            top: 239px;
            left: 408px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/ah.gif);
            }
        }
        .bj {
            width: 17px;
            height: 18px;
            top: 166px;
            left: 407px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/bj.gif);
            }
        }
        .chq {
            width: 51px;
            height: 47px;
            top: 275px;
            left: 310px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/chq.gif);
            }
        }
        .gd {
            width: 81px;
            height: 63px;
            top: 352px;
            left: 363px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/gd.gif);
            }
        }
        .gs {
            width: 148px;
            height: 123px;
            top: 148px;
            left: 199px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/gs.gif);
            }
        }
        .gx {
            width: 80px;
            height: 59px;
            top: 344px;
            left: 305px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/gx.gif);
            }
        }
        .gz {
            width: 62px;
            height: 52px;
            top: 312px;
            left: 295px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/gz.gif);
            }
        }
        .hainan {
            width: 25px;
            height: 21px;
            top: 415px;
            left: 348px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/hainan.gif);
            }
        }
        .hb {
            width: 82px;
            height: 49px;
            top: 261px;
            left: 344px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/hb.gif);
            }
        }
        .heb {
            width: 58px;
            height: 81px;
            top: 146px;
            left: 388px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/heb.gif);
            }
        }
        .fj {
            width: 47px;
            height: 59px;
            top: 313px;
            left: 425px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/fj.gif);
            }
        }
        .hen {
            width: 62px;
            height: 56px;
            top: 223px;
            left: 362px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/hen.gif);
            }
        }
        .hlj {
            width: 116px;
            height: 106px;
            top: 13px;
            left: 431px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/hlj.gif);
            }
        }
        .hun {
            width: 56px;
            height: 65px;
            top: 298px;
            left: 350px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/hun.gif);
            }
        }
        .jl {
            width: 88px;
            height: 59px;
            top: 96px;
            left: 447px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/jl.gif);
            }
        }
        .js {
            width: 56px;
            height: 50px;
            top: 232px;
            left: 420px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/js.gif);
            }
        }
        .jx {
            width: 51px;
            height: 67px;
            top: 297px;
            left: 399px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/jx.gif);
            }
        }
        .ln {
            width: 61px;
            height: 53px;
            top: 129px;
            left: 434px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/ln.gif);
            }
        }
        .nmg {
            width: 226px;
            height: 196px;
            top: 17px;
            left: 245px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/nmg.gif);
            }
        }
        .nx {
            width: 34px;
            height: 47px;
            top: 192px;
            left: 301px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/nx.gif);
            }
        }
        .qh {
            width: 132px;
            height: 96px;
            top: 184px;
            left: 158px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/qh.gif);
            }
        }
        .sc {
            width: 111px;
            height: 96px;
            top: 250px;
            left: 233px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/sc.gif);
            }
        }
        .sd {
            width: 69px;
            height: 45px;
            top: 198px;
            left: 407px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/sd.gif);
            }
        }
        .shx {
            width: 55px;
            height: 93px;
            top: 188px;
            left: 314px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/shx.gif);
            }
        }
        .sx {
            width: 37px;
            height: 73px;
            top: 173px;
            left: 361px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/sx.gif);
            }
        }
        .tj {
            width: 15px;
            height: 20px;
            top: 175px;
            left: 427px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/tj.gif);
            }
        }
        .tw {
            width: 17px;
            height: 40px;
            top: 347px;
            left: 473px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/tw.gif);
            }
        }
        .xj {
            width: 208px;
            height: 156px;
            top: 63px;
            left: 25px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/xj.gif);
            }
        }
        .xz {
            width: 200px;
            height: 124px;
            top: 207px;
            left: 48px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/xz.gif);
            }
        }
        .yn {
            width: 92px;
            height: 92px;
            top: 313px;
            left: 229px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/yn.gif);
            }
        }
        .zj {
            width: 40px;
            height: 50px;
            top: 275px;
            left: 444px;
            &:hover,
            &.on {
                background-image: url(/public/assets/static/style/images/maps/zj.gif);
            }
        }
        .sh {
            width: 50px;
            height: 30px;
            top: 259px;
            left: 480px;
        }
    }
    .map-select-box {
        margin: 0 auto;
        width: percent(1100, 1360);
        display: flex;
        margin-bottom: 15px;
        @media screen and (max-width: 1365px) {
            width: 100%;
        }
        @media screen and (max-width: 750px) {
            display: block;
        }
    }
    .map-select-wp {
        flex: 2;
        margin-right: 5px;
        position: relative;
        .map-select {
            display: block;
            width: 100%;
            background-color: #fff;
            height: 44px;
            line-height: 44px;
            border: none;
            font-size: 12px;
            color: #4f4f4f;
            box-sizing: border-box;
            padding: 0 10px;
            .customSelectInner {
                position: relative;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    border-top: 4px solid #333;
                    border-right: 4px solid transparent;
                    border-left: 4px solid transparent;
                    content: '';
                    margin-top: -2px;
                }
            }
        }
        select {
            opacity: 0;
            z-index: 5;
            cursor: pointer;
        }
        @media screen and (max-width: 750px) {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
    .map-input-wp {
        flex: 3;
        display: flex;
        input[type="text"] {
            flex: 1;
            height: 34px;
            line-height: 34px;
            border: none;
            background: #fff;
            box-sizing: border-box;
            color: #4f4f4f;
            padding: 0 10px;
            -webkit-appearance: none;
        }
    }
    .map-input-submit {
        width: 48px;
        height: 34px;
        position: relative;
        background-color: #434343;
        text-align: center;
        line-height: 34px;
        color: #fff;
        font-size: 16px;
        &:after {
            content: '\e6d6';
        }
        input[type="submit"] {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: none;
            border: none;
            cursor: pointer;
            -webkit-appearance: none;
        }
    }
    .map-select-word {
        font-size: 11px;
        color: #b4b4b4;
        line-height: 1.3;
        width: 260px;
    }
    .project-menu {
        margin-bottom: px2rem(20px);
    }
}
