//版心
.w1700{
    margin: 0 auto;
    width: 1700px;
}
.w1500{
    margin: 0 auto;
    width: 1500px;
}
.w1360{
    margin: 0 auto;
    width: 1360px;
}
@media (max-width: 1720px) {
    .w1700{
        width: 1560px;
    }
}
@media (max-width: 1600px) {
    .w1700{
        width: 1400px;
    }
}
@media (max-width: 1560px) {
    .w1500{
        width: 1300px;
    }
}
@media (max-width: 1440px) {
    .w1700{
        width: 1300px;
    }
}
@media (max-width: 1365px) {
    .w1700,
    .w1500,
    .w1360{
        margin: 0 .15rem;
        width: auto;
    }
}
//背景色
#web{
    background-color: #f5f5f5;
}
//头部
header{
    position: absolute;
    width: 100%;
    z-index: 99;
    background-color: rgba($color: #071728, $alpha: .6);
    h1{
        margin-top: 15px;
        float: left;
        img{
            width: px2rem(79px);
            height: px2rem(91px);
        }
    }
    .header{
        margin: 0 auto;
        width: 1380px;
        height: px2rem(118px);
    }
    .header-right{
        display: flex;
        align-items: center;
        float: right;
    }
    .header-right-mobile{
        display: none;
        align-items: center;
        height: 100%;
        float: right;
        em{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: px2rem(30px);
            width: px2rem(48px);
            height: px2rem(48px);
            font-size: px2rem(26px);
            color: #fff;
            background-color: #e67817;
            border-radius: 50%;
        }
        .language{
            width: px2rem(48px);
            height: px2rem(48px);
            font-size: px2rem(19px);
        }
    }
    .nav{
        display: flex;
        li >a{
            display: flex;
            align-items: center;
            padding: 0 30px;
            height: px2rem(118px);
            font-size: 16px;
            color: #fff;
            &:hover{
                opacity: .8;
            }
        }
        li{
            position: relative;
            .sub{
                display: none;
                position: absolute;
                padding-bottom: 10px;
                width: 120%;
                background-color: #fff;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                z-index: 2;
                text-align: center;
                a{
                    display: block;
                    box-sizing: border-box;
                    padding: 15px;
                    font-size: 14px;
                    color: #555;
                    border-bottom: 1px dashed #f5f5f5;
                    &:hover{
                        background-color: $hoverColor;
                        color: #fff;
                        border-bottom: 1px solid $hoverColor;
                    }
                } 
                &-item:last-child{
                    a{
                        border-bottom: 0; 
                    }  
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    width: 0;
                    height: 0;
                    border: 10px solid transparent;
                    border-bottom-color: #fff;
                }
            }
            &::before{
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                display: block;
                width: 1px;
                height: 7px;
                background-color: rgba($color: #fff, $alpha: .22);
            }
            &:first-child{
                &::before{
                    display: none;
                }
            }
            &:hover{
                .sub{
                    display: block;
                }
            }
        }
    }
    .language,
    .search-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        em{
            font-size: 14px;
        }
    }
    .language{
        background-color: $hoverColor;
    }
    .search-btn{
        position: relative;
        background-color: $mainColor;
    }
    .search{
        display: none;
        position: absolute;
        padding: 8px 0;
        right: -5px;
        bottom: 0;
        transform: translateY(100%);
        input {
            &[type="search"] {
                box-sizing: border-box;
                padding-left: 10px;
                width: 150px;
                height: 30px;
                border-radius: 15px;
                background-color: #f9f9f9;
                outline: none;
                border: 0;
            }
        }
        input::-webkit-search-cancel-button{
            display: none;
        }
        input[type="search"]::-ms-clear{
            display: none;
        }
        button[type="submit"] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            width: 20px;
            height: 20px;
            border: 0;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;
        }
    }
    @media (max-width: 1400px) {
        .header{
            width: 1300px;
        }
    }
    @media (max-width: 1350px) {
        h1{
            margin-top: px2rem(10px);
        }
        .header{
            margin: 0 .15rem;
            width: auto;
        }
        .header-right{
            display: none;
        }
        .header-right-mobile{
            display: flex;
        }
    }
}
//底部
footer{
    a:hover{
        text-decoration: underline;
    }
    .footer-w{
        margin: 0 auto;
        width: 1600px;
    }
    .bdsharebuttonbox{
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 14px 0 0;
            width: 36px;
            height: 36px;
            font-size: 20px;
            text-indent: 0;
            color: #272636;
            border-radius: 50%;
            background-color: rgba($color: #b0bbd1, $alpha: .4);
            background-image: url() !important;
            background-size: 0%;
        }
        a:hover{
            text-decoration: none;
        }
        .OA{
            font-size: 12px;
            font-weight: 700;
        }
    }
    .footer-top{
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 75px 0 90px;
        background-color: #2a537a;
        dl{
            box-sizing: border-box;
            padding: 0 percent(10, 1600) 0 percent(40, 1600);
            width: percent(145, 1600);
            border-right: 1px solid rgba($color: #fff, $alpha: .04);
            dt{
                margin-bottom: 25px;
                font-size: 16px;
            }
            dd{
                margin-bottom: 17px;
                font-size: 14px;
                a{
                    max-width: 64px;
                    color: rgba($color: #fff, $alpha: .33);
                }
            }
            a{
                color: #fff;
            }
            &:last-child{
                dt{
                    margin-bottom: 26px;
                }
            }
        }
        .telephone{
            display: flex;
            align-items: center;
            color: #fff;
            img{
                margin-right: 12px;
            }
            h3{
                font-size: 36px;
                font-family: Arial;
                line-height: 1;
            }
            h6{
                margin: 0 0 10px 5px;
                font-size: 15px;
                font-family: $SYST;
            }
        }
        
        .ewm{
            margin-left: 32px;
            width: percent(122, 1600);
            img{
                max-width: 100%;
            }
            p{
                margin-top: 12px;
                font-size: 13px;
                color: #fff;
                text-align: center;
            }
        }
        &-left{
            position: absolute;
        }
        &-right{
            display: flex;
            justify-content: flex-end;
        }
    }
    .footer-bottom{
        display: flex;
        align-items: center;
        height: 66px;
        background-color: #203b55;
        span,
        a{
            font-size: 14px;
            color: #fff;
        }
    }
    .footer-mobile{
        display: none;
        padding: px2rem(50px) 0 px2rem(45px);
        background-color: #2a537a;
        text-align: center;
        .i{
            margin-bottom: px2rem(4px);
            img{
                margin-right: px2rem(8px);
                width: px2rem(40px);
                height: px2rem(34px);
                vertical-align: inherit;
            }
            span{
                font-size: px2rem(24px);
                color: #fff;
            }
        }
        .ii{
            font-size: px2rem(36px);
            font-family: Arial;
            color: #fff;
        }
        .iii{
            .bdsharebuttonbox{
                display: inline-block;
                a{
                    margin: px2rem(26px) px2rem(15px) 0 0;
                }
            }
        }
        .iiii{
            margin-bottom: px2rem(30px);
            font-size: px2rem(24px);
            line-height: px2rem(30px);
            color: #fff;
            a{
                color: #fff;
            }
        }
        .iiiii{
            img{
                margin-bottom: px2rem(4px);
                width: px2rem(124px);
                height: px2rem(124px);
            }
            p{
                font-size: px2rem(18px);
                color: #fff;
            }
        }
    }
    @media (max-width: 1640px) {
        .footer-w{
            width: 1400px;
        }
    }
    @media (max-width: 1440px) {
        .footer-w{
            width: 1300px;
        }
    }
    @media (max-width: 1350px) {
        .footer-top,
        .footer-bottom{
            display: none;
        }
        .footer-mobile{
            display: block;
        }
    }
    @media (max-width: 750px) {
        .iii{
            .bdsharebuttonbox{
                a{
                    width: 30px;
                    height: 30px;
                    &::before{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
//横幅
.banner{
    padding-top: percent(550, 1920);
    @include img();
    img:nth-child(2){
        display: none;
    }
    @media (max-width: 1024px) {
        padding-top: percent(450, 750);
        img:nth-child(1){
            display: none;
        }
        img:nth-child(2){
            display: block;
        }
    }
}
//二级导航
.subnav{
    box-shadow: 0 0 20px rgba($color: #000, $alpha: .1);
    background-color: #fff;
    .swiper-wrapper{
        .swiper-slide{
            width: auto;
            position: relative;
            >a{
                display: block;
                padding: 32px 54px;
                font-size: 16px;
                color: #666;
                @media (max-width: 1024px) {
                    padding: px2rem(35px) px2rem(25px);
                }
                @media (max-width: 750px) {
                    font-size: 14px;
                }
            }
            .active,
            :hover{
                color: #fff;
                background-color: $hoverColor;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                right: -1px;
                transform: translateY(-50%);
                width: 1px;
                height: 8px;
                background-color: #ccc;
            }
        }
        .swiper-slide:last-child{
            &:after {
                display: none;
            }
        }
    }
}
//三级导航
.sonsub{
    margin: px2rem(40px) 0 px2rem(50px);
    .swiper-wrapper{
        .swiper-slide{
            margin-left: 6px;
            width: auto;
            position: relative;
            >div{
                display: block;
                padding: 15px 70px;
                font-size: 16px;
                color: #666;
                background-color: #fff;
                @media (max-width: 1024px) {
                    padding: px2rem(25px);
                }
                @media (max-width: 750px) {
                    font-size: 14px;
                }
            }
        }
        .on,
        .swiper-slide:hover{
            >div{
                color: #fff;
                background-color: #f09c49;
            }
        }
        .swiper-slide:first-child{
            margin-left: 0;
        }
    }
}
.system-sonsub{
    .swiper-wrapper{
        .swiper-slide{
            >div{
                padding: 15px 0;
                min-width: 210px;
                text-align: center;
            }
        }
    }
    @media (max-width: 750px) {
        margin-left: .15rem;
        margin-right: .15rem;
        .swiper-wrapper{
            .swiper-slide{
                div{
                    padding: px2rem(25px) 0;
                    min-width: 170px;
                }
            }
        }
    }
}
//蒙版
.mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
//移动端二级导航
.nav-mobile {
    position: absolute;
    width: 100%;
    top: px2rem(145px);
    right: 0;
    bottom: 0;
    z-index: 999;
    background: #f0f0f2;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9902;
        box-sizing: border-box;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: px2rem(100px);
            left: 0;
            background-color: rgba($color: #f0f0f2, $alpha: 1.0);
            z-index: 102;
        }
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 px2rem(20px);
        width: 100%;
        height: px2rem(145px);
        box-sizing: border-box;
        background-color: $hoverColor;
        color: #fff;
        z-index: 103;
        position: absolute;
        top: 0;
        left: 0;
        .mobile-menu-btn2 {
            font-size: px2rem(50px);
            background-color: rgba($color: #000, $alpha: 0);
        }
    }
    &-search {
        display: flex;
        z-index: 104;
        width: 100%;
        height: px2rem(160px);
        background-color: #f0f0f2;
        box-sizing: border-box;
        form{
            width: 100%;
            input {
                &[type="search"] {
                    padding-left: px2rem(40px);
                    width: 100%;
                    height: 100%;
                    font-size: px2rem(36px);
                    background-color: #f9f9f9;
                    outline: none;
                    border: 0;
                }
                &[type="submit"] {
                    position: absolute;
                    right: px2rem(45px);
                    width: px2rem(45px);
                    height: px2rem(45px);
                    top: px2rem(60px);
                    border: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: rgba(0, 0, 0, 0);
                }
            }
        }
        input::-webkit-search-cancel-button{
            display: none;
        }
        input[type="search"]::-ms-clear{
            display: none;
        }
        i{
            position: absolute;
            right: px2rem(45px);
            width: px2rem(45px);
            height: px2rem(45px);
            top: px2rem(60px);
            font-size: px2rem(45px);
            color: #666;
        }
    }
    &-wp {
        position: relative;
        ul{
            li{
                border-bottom: 1px solid #e2e2e2;
                background-color: #fff;
            }
        }
    }
    &-title {
        position: relative;
        margin: 0 px2rem(30px) 0 px2rem(60px); 
        > a {
            position: relative;
            display: block;
            line-height: px2rem(130px);
            font-size: px2rem(36px);
            color: #666;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            display: block;
            width: px2rem(40px);
            height: 100%;
            top: 0;
            right: 0;
            color: #666;
            line-height: px2rem(130px);
            text-align: center;
            font-size: px2rem(30px);
            transition: transform .2s ease;
            &:before {
                content: '\e642';
                font-family: "iconfont";
                display: inline-block;
                transform: rotate(180deg);
            }
        }
        &.open {
            i {
                &:before {
                    transform: rotate(-90deg);
                }
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub-moilde {
        display: none;
        li {
            border-top: 1px solid #e2e2e2;
            border-bottom: 0;
            padding-left: px2rem(64px);
            position: relative;
            height: px2rem(130px);
            overflow: hidden;
            a {
                display: block;
                line-height: px2rem(130px);
                font-size: px2rem(28px);
                height: px2rem(84px);
                color: #666;
                overflow: hidden;
            }
            ::before{
                content: '- ';
            }
        }
    }
}
//视频弹窗
.video-div{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #1a1a1a, $alpha: .8);
    z-index: 10;
    .video-box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 860px;
        max-width: 100%;
        height: auto;
    }
}
.X{
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-120%);
    color: #fff;
    width: px2rem(30px);
    height: px2rem(30px);
    line-height: px2rem(30px);
    text-align: center;
    z-index: 11;
    cursor: pointer;
    i{
        font-size: px2rem(30px);
    }
}
.pop-video{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 11;
}