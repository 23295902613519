.index-banner{
    margin-bottom: 85px;
    .swiper-slide{
        padding-top: percent(920, 1920);
        @include img();
        img:nth-child(2){
            display: none;
        }
    }
    .swiper-button{
        display: flex;
        position: absolute;
        right: percent(110, 1920);
        bottom: 0;
        z-index: 1;
        .index-banner-button-prev,
        .index-banner-button-next{
            display: flex;
            align-items: center;
            justify-content: center;
            position: static;
            outline: none;
            width: 100px;
            height: 60px;
            &::after{
                content: "\e631";
                font-family: "iconfont";
                font-size: 18px;
                color: #fff;
            }
        }
        .index-banner-button-prev{
            background-color: #0087da;
        }
        .index-banner-button-next{
            background-color: #f5932a;
            &::after{
                transform: rotate(180deg);
            }
        }
    }
    @media (max-width: 1365px) {
        margin-bottom: 0;
    }
    @media (max-width: 768px) {
        .swiper-slide{
            padding-top: percent(555, 750);
            img:nth-child(1){
                display: none;
            }
            img:nth-child(2){
                display: block;
            }
        }
        .swiper-button{
            display: none;
        }
    }
}
.index-new{
    margin-bottom: 75px;
    .flex{
        display: flex;
        align-items: flex-start;
    }
    &-title{
        margin: 20px 0 30px;
        &-left{
            width: percent(525, 1000);
            h4{
                font-size: 24px;
                color: #3e3e3e;
            }
            img{
                margin: 0 px2rem(9px) 0 6px;
            }
        }
        &-right{
            width: percent(475, 1000);
            font-size: 0;
            li{
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                padding: 0 18px;
                font-size: 16px;
                color: #777;
                cursor: pointer;
                &::before{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 9px;
                    background-color: #c7c7c7;
                }
                &:first-child{
                    &::before{
                        display: none;
                    }
                }
            }
            .on{
                color: #fd9437;
            }
        }
    }
    &-left{
        position: relative;
        width: percent(1020, 1700);
        .box{
            position: relative;
            .flex{
                flex-wrap: wrap;
                align-items: center;
            }
            .flex-left{
                position: relative;
                width: percent(555, 1020);
                .index-new-swiper{
                    .swiper-slide{
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        &:hover{
                            h5{
                                color: #fd9437;
                            }
                        }
                    }
                    .img-box{
                        width: percent(215, 555);
                        div{
                            width: 100%;
                            padding-top: percent(160, 215);
                            @include img();
                        }
                    }
                    .info-box{
                        box-sizing: border-box;
                        padding-right: 24px;
                        width: percent(320, 555);
                        font-size: 14px;
                        color: #717171;
                        p{
                            line-height: 28px;
                            @include multiEllipsis(3);
                        }
                        h5{
                            margin-bottom: 5px;
                            font-size: 18px;
                            font-weight: 700;
                            color: #3e3e3e;
                            @include multiEllipsis(1);
                        }
                        h6{
                            font-size: 12px;
                            color: #9f9f9f;
                        }
                        .line{
                            margin: 15px 0;
                            width: px2rem(19px);
                            height: px2rem(4px);
                            background-color: #cfcfcf;
                        }
                    }
                }
                .swiper-pagination-bullets{
                    bottom: -36px;
                }
                .swiper-pagination-bullet{
                    margin: 0 px2rem(4px);
                    outline: none;
                    opacity: 1;
                    background-color: #a2a2a2;
                }
                .swiper-pagination-bullet-active{
                    background-color: #f7a650;
                }
            }
            .flex-right{
                width: percent(465, 1020);
                ul{
                    border-left: 1px solid rgba($color: #000, $alpha: .05);
                    box-sizing: border-box;
                    padding: 0 35px 0 40px;
                }
                li{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    a{
                        width: 80%;
                        font-size: 14px;
                        color: #585858;
                        @include multiEllipsis(1);
                        &:hover{
                            color: #fd9437;
                        }
                    }
                    span{
                        font-size: 14px;
                        color: #9f9f9f;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -20px;
                        transform: translateY(-50%);
                        box-sizing: border-box;
                        width: px2rem(10px);
                        height: px2rem(10px);
                        background-color: #f3f3f3;
                        border-radius: 50%;
                        border: 1px solid #e1e1e1;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .more-btn {
                    text-align: right;
                    padding-top: 20px;
                    padding-right: 35px;
                    a {
                        font-size: 14px;
                        color: #777;
                        &:hover {
                            color: #fd9437;
                        }
                    }
                }
            }
        }
    }
    &-right{
        width: percent(680, 1700);
        #index-focusnew-swiper{
            .swiper-slide{
                display: block;
                padding-top: percent(320, 680);
                height: 0;
                @include img();
            }
            .swiper-pagination{
                box-sizing: border-box;
                padding-right: px2rem(24px);
                text-align: right;
            }
            .swiper-pagination-bullets{
                bottom: percent(42, 323) !important;
            }
            .swiper-pagination-bullet{
                margin: 0 3px;
                width: 10px;
                height: 10px;
                outline: none;
                opacity: 1;
                background-color: #fff;
            }
            .swiper-pagination-bullet-active{
                background-color: #0087da;
            }
            .mask{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url(/public/assets/static/style/images/sy-mask_03.png);
                background-repeat: no-repeat;
                background-size: cover;
            }
            .focusnew-title{
                position: absolute;
                left: percent(65, 700);
                bottom: percent(86, 323);
                width: 170px;
                h5{
                    font-size: px2rem(26px);
                    font-weight: 700;
                    color: #fff;
                }
                h4{
                    margin: px2rem(10px) 0 px2rem(16px);
                    font-size: px2rem(38px);
                    font-weight: 700;
                    color: #8af8ff;
                    line-height: 1;
                }
                div{
                    width: 24px;
                    height: 4px;
                    background-color: #b6b8c3;
                }
            }
            .focusnew{
                position: absolute;
                left: percent(65, 700);
                bottom: percent(38, 323);
                width: percent(510, 700);
                font-size: 15px;
                color: #fff;
                @include multiEllipsis(1);
            }
        }
    }
    @media (max-width: 1600px) {
        &-left{
            .box{
                .flex-left{
                    .swiper-pagination-bullets{
                        bottom: 0px;
                    }
                }
                .flex-right{
                    li{
                        a{
                            width: 70%;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1365px) {
        margin-bottom: px2rem(50px);
        .flex{
            flex-wrap: wrap;
        }
        &-title{
            align-items: center !important;
            margin: px2rem(50px) 0 px2rem(42px);
            &-left{
                h4{
                    font-size: px2rem(30px);
                }
                img{
                    width: px2rem(38px);
                    height: px2rem(38px);
                }
            }
            &-right{
                text-align: right;
                li{
                    padding: 0 px2rem(32px);
                    font-size: px2rem(22px);
                }
                li:last-child{
                    padding-right: 0;
                }
            }
        }
        &-left{
            width: 100%;
            .box{
                .flex-left{
                    padding-bottom: px2rem(56px);
                    width: 100%;
                    border-bottom: 1px solid rgba($color: #000, $alpha: .05);
                    .index-new-swiper{
                        .img-box{
                            margin-left: 1px;
                            width: px2rem(215px);
                            div{
                                padding-top: px2rem(160px);
                            }
                        }
                        .info-box{
                            flex: 1;
                            padding: 0 0 0 px2rem(18px);
                            width: auto;
                            font-size: px2rem(24px);
                            p{
                                line-height: px2rem(36px);
                                @include multiEllipsis(2);
                            }
                            h5{
                                font-size: px2rem(26px);
                            }
                            h6{
                                font-size: px2rem(18px);
                            }
                            .line{
                                margin: px2rem(16px) 0 px2rem(8px);
                            }
                        }
                    }
                    .swiper-pagination-bullets{
                        bottom: px2rem(28px);
                    }
                }
                .flex-right{
                    width: 100%;
                    ul{
                        border-left: 0;
                        padding: px2rem(30px) 0 px2rem(30px) px2rem(25px);
                    }
                    li{
                        margin-bottom: px2rem(24px);
                        a,
                        span{
                            font-size: px2rem(24px);
                        }
                        &::before{
                            left: px2rem(-24px);
                        }
                    }
                    .more-btn {
                        padding-right: 0;
                        padding-top: 0;
                        padding-bottom: px2rem(30px);
                        a {
                            font-size: px2rem(24px);
                        }
                    }
                }
            }
        }
        &-right{
            width: 100%;
            #index-focusnew-swiper{
                .swiper-pagination-bullets{
                    bottom: px2rem(36px) !important;
                }
                .focusnew-title{
                    left: px2rem(46px);
                    bottom: px2rem(150px);
                    div{
                        display: none;
                    }
                }
                .focusnew{
                    left: px2rem(46px);
                    bottom: px2rem(30px);
                    width: percent(560, 690);
                    font-size: px2rem(26px);
                    color: #fff;
                    @include multiEllipsis(1);
                }
            }
        }
    }
}
.index-plate{
    margin-bottom: 60px;
    &-bg{
        position: relative;
        padding-top: percent(430, 1700);
        background-image: url(/public/assets/static/style/images/sy-bg_03.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
    .flex-box{
        position: absolute;
        right: 65px;
        bottom: 0;
        display: flex;
    }
    .flex-item{
        display: block;
        padding: 65px 45px 68px;
        width: 240px;
        background-color: #fff;
        text-align: center;
        em{
            display: block;
            margin-bottom: 16px;
            font-size: px2rem(50px);
            color: #f5932a;
            transition: all .3s;
        }
        h5{
            font-size: 22px;
            color: #000;
        }
        h6{
            margin-bottom: 16px;
            font-size: 12px;
            color: #333;
            opacity: .2;
        }
        p{
            margin-bottom: 16px;
            font-size: 15px;
            line-height: 24px;
            color: #888;
        }
        &:nth-child(odd){
            background-color: #f5f5f5;
        }
        &:hover{
            em{
                transform: rotateY(180deg);
            }
        }
    }
    .strategy{
        display: block;
        position: absolute;
        top: 50%;
        left: percent(30, 1700);
        transform: translateY(-48%);
        span{
            position: absolute;
            top: 115px;
            right: -80px;
            font-size: 24px;
            color: #fff;
            em{
                margin-right: px2rem(8px);
                font-size: px2rem(45px);
                color: #8af8ff;
                vertical-align: middle;
            }
        }
        img{
            transition: all .3s;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    @media (max-width: 1720px) {
        .flex-box{
            right: 45px;
        }
        .flex-item{
            padding: 45px 25px;
            width: 240px;
        }
    }
    @media (max-width: 1600px) {
        .flex-box{
            right: 25px;
        }
        .flex-item{
            padding: 35px 20px;
            width: 200px;
        }
        .strategy{
            img{
                transform: scale(.8);
            }
            &:hover{
                img{
                    transform: scale(.9);
                }
            }
        }
    }
    @media (max-width: 1440px) {
        .flex-item{
            padding: 20px;
            width: 180px;
        }
        .strategy{
            left: 0;
            img{
                transform: scale(.75);
            }
            &:hover{
                img{
                    transform: scale(.8);
                }
            }
        }
    }
    @media (max-width: 1365px) {
        margin-bottom: px2rem(50px);
        &-bg{
            padding-top: 0;
            background-size: 0;
        }
        .flex-box{
            position: static;
            display: block;
        }
        .flex-item{
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: px2rem(43px) px2rem(46px) px2rem(30px) px2rem(36px);
            width: 100%;
            em{
                margin-bottom: px2rem(10px);
            }
            h5{
                font-size: px2rem(26px);
            }
            h6{
                margin-bottom: 0;
                line-height: 1.2;
            }
            p{
                margin-bottom: px2rem(20px);
                font-size: px2rem(26px);
                line-height: px2rem(36px);
            }
            .left{
                width: px2rem(195px);
            }
            .right{
                text-align: left;
                width: px2rem(380px);
            }
        }
        .strategy{
            position: relative;
            transform: translateY(0);
            padding-top: px2rem(525px);
            background-image: url(/public/assets/static/style/images/sy-bgm1_03.jpg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -40%);
                width: px2rem(502px);
                height: px2rem(372px);
            }
            span{
                right: auto;
                left: 50%;
                top: px2rem(50px);
                transform: translate(-50%, 0);
                margin-top: 0;
                font-size: px2rem(26px);
            }
            &:hover{
                img{
                    transform: translate(-50%, -40%);
                }
            }
        }
    }
}
.index-project{
    margin-bottom: 90px;
    &-box{
        display: flex;
        flex-wrap: wrap;
        img{
            transition: all .3s;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    &-title{
        width: 100%;
        h4{
            position: relative;
            margin-bottom: 16px;
            font-size: 18px;
            color: #3e3e3e;
            vertical-align: middle;
        }
        a{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 12px;
            color: #888;
        }
        img{
            margin-right: px2rem(10px);
            vertical-align: middle;
        }
    }
    .big-item{
        width: percent(846, 1700);
        #index-project-swiper{
            .swiper-slide{
                display: block;
                padding-top: percent(537, 846);
                @include img();
                .info{
                    position: absolute;
                    left: 30px;
                    bottom: 30px;
                    width: percent(555, 846);
                    color: #fff;
                    h4{
                        margin-bottom: 5px;
                        font-size: 24px;
                        font-weight: 700;
                    }
                    p{
                        font-size: 15px;
                        @include multiEllipsis(1);
                    }
                }
            }
            .swiper-button{
                display: flex;
                position: absolute;
                right: 0;
                bottom: 0;
                .swiper-button-prev,
                .swiper-button-next{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: static;
                    outline: none;
                    width: 64px;
                    height: 64px;
                    &::after{
                        content: "\e642";
                        font-family: "iconfont";
                        font-size: 18px;
                        color: #fff;
                    }
                }
                .swiper-button-prev{
                    background-color: #f09c49;
                }
                .swiper-button-next{
                    background-color: $hoverColor;
                    &::after{
                        transform: rotate(180deg);
                    }
                }
            }
            .swiper-pagination-project{
                display: none;
                text-align: right;
                box-sizing: border-box;
                padding-right: px2rem(16px);
                .swiper-pagination-bullet{
                    margin: 0 px2rem(4px);
                    opacity: 1;
                    background-color: #fff;
                }
                .swiper-pagination-bullet-active{
                    background-color: #f5932a;
                }
            }
        }
    }
    .small-item{
        display: flex;
        flex-wrap: wrap;
        width: percent(854, 1700);
        .item{
            display: block;
            margin: percent(17, 854) 0 0 percent(17, 854);
            width: percent(410, 854);
            padding-top: percent(260, 854);
            @include img();
            h4{
                position: absolute;
                left: 50%;
                bottom: px2rem(16px);
                transform: translateX(-50%);
                width: 90%;
                font-size: px2rem(24px);
                font-weight: 700;
                color: #fff;
                text-align: center;
                @include multiEllipsis(1);
            }
            &:nth-child(1),
            &:nth-child(2){
                margin-top: 0;
            }
        }
    }
    @media (max-width: 1365px) {
        margin-bottom: px2rem(50px);
        &-title{
            h4{
                margin-bottom: px2rem(20px);
                font-size: px2rem(30px);
            }
            a{
                display: none;
            }
            img{
                width: px2rem(38px);
                height: px2rem(40px);
            }
        }
        .big-item{
            width: 100%;
            #index-project-swiper{
                .swiper-slide{
                    .info{
                        width: px2rem(535px);
                        left: px2rem(22px);
                        bottom: px2rem(20px);
                        h4{
                            font-size: px2rem(26px);
                            margin-bottom: 0;
                        }
                        p{
                            font-size: px2rem(26px);
                        }
                    }
                }
                .swiper-button{
                    display: none;
                }
                .swiper-pagination-project{
                    display: block;
                }
            }
        }
        .small-item{
            width: 100%;
            .item{
                width: 49%;
                margin: 2% 0 0 2%;
                h4{
                    width: 75%;
                }
                &:nth-child(1),
                &:nth-child(2){
                    margin-top: 2%;
                }
                &:nth-child(2n+1){
                    margin-left: 0;
                }
            }
        }
    }
}